/* istanbul ignore file */

import { graphql } from "gatsby";
import React from "react";
import type { VideoPlayerSectionFragment } from "../../../graphql-types";
import { VideoPlayer } from "../VideoPlayer";

export default function VideoPlayerSection(props: VideoPlayerSectionFragment) {
  const { autoPlay, video } = props;
  if (!video.cdnVideo) {
    throw new Error("Could not get CDN video path");
  }
  return (
    <div className="max-w-lg mx-auto px-4">
      <VideoPlayer
        className="w-full h-auto max-w-full border border-gray-200 rounded-lg"
        data-testid="video"
        poster={video.cdnVideo.posterPath}
        path={video.cdnVideo.path}
        title={video.description || undefined}
        autoPlay={autoPlay}
      />
    </div>
  );
}

export const fragment = graphql`
  fragment VideoPlayerSection on Video {
    autoPlay
    video {
      description
      cdnVideo {
        path
        posterPath
      }
    }
  }
`;
